import React from 'react';
import SEO from '../../components/App/SEO';
import Layout from '../../components/App/Layout';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { graphql } from 'gatsby';
const img = '/img/corporate/BlueMail_Corporate_Strip_Priority_Support.png'

const prioritySupportPage = ({data}) => (
    <Layout env={data.site.siteMetadata.env}>
        <SEO 
        postTitle='Priority Support | BlueMail App' 
        postDescription='Priority Support Your business depends on 100% uptime and with BlueMail Plus&#8217;s Priority Support, assistance is a simple email away. 24 Hour Priority support ensures that when you are in need of fast assistance, your support tickets are handled promptly...'
        postImage={img}
        postURL='corporate/priority-support'
        postSEO
        />
        <Navbar />
        <div className="priority-support-area container pt-120 pb-100">
            <div className='row'>
            <div className='col-12' style={{textAlign: 'center'}}>
                <img src={img} alt='BlueMail Corporate Priority Support' style={{width: '100%'}}/>
            </div>
            </div>
            <div className='row'>
                <div className='col-12' style={{textAlign: 'center', marginTop: '1.8em'}}>
                    <h1>Priority Support</h1>
                    <hr />
                </div>
                <div className='col-12'>
                <p className="mt-30 center-text">
                Your business depends on 100% uptime and with BlueMail Plus’s Priority Support, assistance is a simple email away. 24 Hour Priority support ensures that when you are in need of fast assistance, your support tickets are handled promptly by a team of highly qualified professionals.
                </p>
                </div>
            </div>
        </div>
        <Footer />
    </Layout>
)

export default prioritySupportPage

export const query = graphql`
query PrioritySupportPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`